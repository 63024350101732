<template>
  <v-footer padless>
    <v-card flat tile class="primary white--text text-center">
      <v-card-text>
        <v-btn v-for="(icon, i) in icons" :key="i" class="mx-4 white--text" :href="icon.link" target="_blank" icon>
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        <v-btn text color="white" @click="goToPrivacyPolicyPage">Privacy Policy</v-btn>
        <v-btn text color="white" @click="goToTermsofServicePage">Terms of Service</v-btn>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        <strong>&copy; 2024 Our Nation Express Limited. All rights reserved.</strong>
      </v-card-text>
      <v-card-text class="white--text">
        Powered by <a href="https://panemtech.com" style="color: white;">Panem Technologies
          Limited</a>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "",
      },
      {
        text: "mdi-twitter",
        link: "",
      },
      {
        text: "mdi-instagram",
        link: "https://www.instagram.com/OurNationExpressLimited/",
      },
    ],
  }),
  methods: {
    goToPrivacyPolicyPage() {
      // this.$router.push({ name: 'rates-list' });
      this.$router.push({ name: 'privacy-policy' });
    },
    goToTermsofServicePage() {
      this.$router.push({ name: 'terms-of-service' });
    }
  },
};
</script>
