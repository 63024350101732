












import PrivacyPolicy from '../../components/privacypolicy/PrivacyPolicy.vue'
import Navigation from '../../components/home/Navigation.vue';
import FootSection from '../../components/home/Footer.vue';
import Vue from 'vue'
export default Vue.extend({
  components: { Navigation, PrivacyPolicy, FootSection },
  name: 'PrivacyPolicyPage',
  metaInfo() {
    return {
      title: "Privacy Policy - Our Nation Express",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Welome to Our Nation Express. We value your privacy."
        }
      ]
    };
  },

  data: () => ({
    color: "",
    flat: false,
  }),

  created() {
    this.color = "primary";
    this.flat = true;

  },
  methods: {
    goToHome() {
      this.$router.push({ name: "home" });
    },
    goToAbout() {

      this.$router.push({ name: "home" }).then(() => {
        // Wait for the route to complete, then scroll to the section
        this.$vuetify.goTo("#about_us_1", {
          duration: 500,
          offset: 0,
        });
      });
    },
    goToFaq() {
      this.$router.push({ name: "home" }).then(() => {
        // Wait for the route to complete, then scroll to the section
        this.$vuetify.goTo("#faq", {
          duration: 500,
          offset: 0,
        });
      });
    },
    goToRates() {
      this.$router.push({ name: "home" }).then(() => {
        // Wait for the route to complete, then scroll to the section
        this.$vuetify.goTo("#rates", {
          duration: 500,
          offset: 0,
        });
      });
    },
    goToLogin() {
      this.$router.push({ name: "login" });
    }
  }

})
